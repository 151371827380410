import { BODY_BACKGROUND, BORDER, DARK_MODE, FONT_SIZES, GRAY_SCALE, THEME_COLOR } from 'constants/ThemeConstant';

export function rgba(hex, opacity = 1) {
    if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        throw new Error('Invalid Hex');
    }

    const decimal = parseInt(hex.substring(1), 16);

    const r = (decimal >> 16) & 255;
    const g = (decimal >> 8) & 255;
    const b = decimal & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const baseTheme = {
    borderRadius: 6,
    colorPrimary: THEME_COLOR.BLUE,
    colorSuccess: THEME_COLOR.CYAN,
    colorWarning: THEME_COLOR.GOLD,
    colorError: THEME_COLOR.VOLCANO,
    colorInfo: THEME_COLOR.BLUE,
    colorText: GRAY_SCALE.GRAY,
    colorBorder: BORDER.BASE_COLOR,
    bodyBg: BODY_BACKGROUND,
    controlHeight: 40,
    controlHeightLG: 48,
    controlHeightSM: 36,
    fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
    fontSizeHeading2: 22,
    fontSizeHeading4: 17,
    fontSize: FONT_SIZES.BASE,
};

const getBaseComponentThemeConfig = ({
    Form = {},
    Tabs = {},
    Breadcrumb = {},
    Button = {},
    Menu = {},
    Card = {},
    Table = {},
    Select = {},
    DatePicker = {},
    Radio = {},
    Switch = {},
    Layout = {},
    Input = {},
    Dropdown = {},
    Calendar = {},
    Checkbox = {},
    Modal = {},
} = {}) => {
    return {
        Form: {
            marginLG: 0,
            verticalLabelPadding: 0,
            ...Form,
        },
        Tabs: {
            margin: 0,
            marginSM: 4,
            marginXS: 8,
            marginXXS: 4,
            ...Tabs,
        },
        Button: {
            controlHeight: 34,
            controlHeightLG: 40,
            controlHeightSM: 28,
            ...Button,
        },
        Dropdown: {
            controlPaddingHorizontal: 20,
            controlHeight: 37,
            borderRadiusLG: 10,
            paddingXXS: '4px 0px',
            ...Dropdown,
        },
        Calendar: {
            ...Calendar,
        },
        Checkbox: {
            lineWidth: 2,
            borderRadiusSM: 4,
            colorBorder: '#C5CACD',
            ...Checkbox,
        },
        Card: {
            colorTextHeading: GRAY_SCALE.GRAY_DARK,
            ...Card,
        },
        Layout: {
            bodyBg: BODY_BACKGROUND,
            headerBg: GRAY_SCALE.WHITE,
            ...Layout,
        },
        Breadcrumb: {
            colorTextDescription: GRAY_SCALE.GRAY_LIGHT,
            colorText: baseTheme.colorPrimary,
            colorBgTextHover: 'transparent',
            ...Breadcrumb,
        },
        Menu: {
            itemBg: 'transparent',
            colorActiveBarHeight: 0,
            activeBarWidth: 3,
            horizontalItemSelectedColor: baseTheme.colorPrimary,
            itemHoverColor: baseTheme.colorPrimary,
            itemSelectedColor: baseTheme.colorPrimary,
            itemSelectedBgz: rgba(baseTheme.colorPrimary, 0.1),
            itemHoverBg: 'transparent',
            radiusItem: 0,
            subMenuItemBg: 'transparent',
            itemMarginInline: 0,
            controlHeightLG: 40,
            controlHeightSM: 22,
            ...Menu,
        },
        Input: {
            colorBgContainer: 'rgb(255, 250, 236)',
            controlHeightSM: 28,
            ...Input,
        },
        Pagination: {
            itemSizeSM: 30,
            borderRadius: '10%',
            colorBgContainer: baseTheme.colorPrimary,
            colorPrimary: GRAY_SCALE.WHITE,
            colorPrimaryHover: GRAY_SCALE.WHITE,
        },
        Steps: {
            wireframe: true,
            controlHeight: 32,
            waitIconColor: GRAY_SCALE.GRAY_LIGHT,
        },
        DatePicker: {
            colorBgContainer: 'rgb(255, 250, 236)',
            activeBg: 'rgb(255, 250, 236)',
            hoverBg: 'rgb(255, 250, 236)',
            controlHeight: 40,
            controlHeightSM: 28,
            borderRadiusSM: 6,
            lineWidthBold: 0,
            ...DatePicker,
        },
        Radio: {
            colorBgContainer: 'rgb(255, 250, 236)',
            fontSize: 14,
            fontSizeLG: 14,
            controlHeight: 24,
            controlHeightLG: 24,
            controlHeightSM: 24,
            marginXS: 0,
            ...Radio,
        },
        Switch: {
            //colorPrimary: "rgb(255, 250, 236)",
            ...Switch,
        },
        Slider: {
            colorPrimaryBorder: rgba(baseTheme.colorPrimary, 0.8),
            colorPrimaryBorderHover: baseTheme.colorPrimary,
        },
        Select: {
            selectorBg: 'rgb(255,250,236)',
            controlHeight: 28,
            controlHeightSM: 28,
            controlItemBgActive: rgba(baseTheme.colorPrimary, 0.1),
            ...Select,
        },
        TreeSelect: {
            controlHeightSM: 24,
        },
        Tooltip: {
            colorBgDefault: rgba(GRAY_SCALE.DARK, 0.75),
            controlHeight: 32,
        },
        Timeline: {
            lineType: 'dashed',
        },
        Tag: {
            lineHeight: 2.1,
        },
        Table: {
            colorText: GRAY_SCALE.GRAY,
            tableHeaderBg: 'transparent',
            paddingXS: 3,
            cellPaddingInline: 8,
            cellPaddingInlineSM: 8,
            // rowHoverBg: '#fff7e6',
            // rowSelectedHoverBg: '#fff7e6',
            ...Table,
        },
        Modal: {
            ...Modal,
        },
    };
};

export const lightTheme = {
    token: {
        ...baseTheme,
        colorTextBase: GRAY_SCALE.GRAY,
    },
    components: getBaseComponentThemeConfig({
        Card: {
            colorBorderSecondary: '#dfdfdf',
        },
        Table: {
            borderColor: '#dfdfdf',
        },
        Button: {
            btnStyles: {
                primary: { background: '#1677ff', color: 'white', paddingBottom: '1px' },
                success: { background: '#389e0d', color: 'white', paddingBottom: '1px' },
                warning: { background: '#fa8c16', color: 'white', paddingBottom: '1px' },
                danger: { background: '#cf1322', color: 'white', paddingBottom: '1px' },
                disabled: { color: '#8c8c8c', paddingBottom: '1px' },
            },
        },
    }),
};

export const darkTheme = {
    token: {
        ...baseTheme,
        colorTextBase: DARK_MODE.TEXT_COLOR,
        colorBgBase: DARK_MODE.BG_COLOR,
        colorBorder: DARK_MODE.BORDER_BASE_COLOR,
        colorText: DARK_MODE.TEXT_COLOR,
    },
    components: getBaseComponentThemeConfig({
        Button: {
            controlOutline: 'transparent',
            colorBorder: DARK_MODE.BORDER_BASE_COLOR,
            colorText: DARK_MODE.TEXT_COLOR,
            btnStyles: {
                primary: { background: '#0958d9', color: 'white', paddingBottom: '1px' },
                success: { background: '#237804', color: 'white', paddingBottom: '1px' },
                warning: { background: '#d46b08', color: 'white', paddingBottom: '1px' },
                danger: { background: '#a8071a', color: 'white', paddingBottom: '1px' },
                disabled: { color: '#8c8c8c', paddingBottom: '1px' },
            },
        },
        Card: {
            colorTextHeading: DARK_MODE.HEADING_COLOR,
        },
        Layout: {
            bodyBg: '#1b2531',
            headerBg: DARK_MODE.BG_COLOR,
        },
        Menu: {
            itemSelectedBg: 'transparent',
            horizontalItemSelectedColor: GRAY_SCALE.WHITE,
            itemHoverColor: GRAY_SCALE.WHITE,
            itemSelectedColor: GRAY_SCALE.WHITE, // 메뉴 타이틀 색상
            itemActiveBg: '#606d99', // 메뉴 클릭 시 색상
            darkItemSelectedBg: '#606d99',
            itemColor: DARK_MODE.TEXT_COLOR,
            colorActiveBarWidth: 0,
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
        },
        Dropdown: {
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
        },
        Calendar: {
            controlItemBgActive: '#303a4e',
        },
        Select: {
            selectorBg: '#606d99',
            controlOutline: 'transparent',
            controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
            controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
        },
        Input: {
            colorBgContainer: '#606d99',
            controlOutline: 'transparent',
        },
        Checkbox: {
            colorBorder: DARK_MODE.BORDER_BASE_COLOR,
        },
        DatePicker: {
            colorBgContainer: '#606d99',
            activeBg: '#606d99',
            hoverBg: '#606d99',
            controlOutline: 'transparent',
            boxShadowSecondary: DARK_MODE.DROP_DOWN_SHADOW,
            controlItemBgActive: DARK_MODE.ACTIVE_BG_COLOR,
            controlItemBgHover: DARK_MODE.HOVER_BG_COLOR,
        },
        Radio: {
            colorBgContainer: '#606d99',
        },
        Switch: {
            colorBgContainer: '#606d99',
        },
        Table: {
            colorText: DARK_MODE.TEXT_COLOR,
            colorTextHeading: DARK_MODE.HEADING_COLOR,
            rowHoverBg: '#363f58',
            rowSelectedHoverBg: '#363f58',
            rowSelectedBg: '#363f58',
        },
        Modal: {},
    }),
};
