import React, { lazy, memo, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { App, ConfigProvider } from 'antd';
import Loading from 'components/shared-components/Loading';
import { darkTheme, lightTheme } from 'configs/ThemeConfig';
import useBodyClass from 'utils/hooks/useBodyClass';
import ko_KR from 'antd/lib/locale/ko_KR';
import { createSelector } from '@reduxjs/toolkit';
import AxiosCustom from '../oznet/AxiosCustom';
import AuthService from '../services/AuthService';
import store from '../store';
import { gfnAlert } from '../oznet/OznetComm';
import { signOut } from 'firebase/auth';
import { AUTH_TOKEN } from '../constants/AuthConstant';
import { useLocation } from 'react-router-dom';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));
const Layouts = () => {
    const location = useLocation();

    const token = useSelector(
        createSelector(
            state => state.auth.token,
            token => token,
        ),
    );
    const blankLayout = useSelector(
        createSelector(
            state => state.theme.blankLayout,
            layout => layout,
        ),
    );
    const Layout = token && !blankLayout ? AppLayout : AuthLayout;
    const direction = useSelector(
        createSelector(
            state => state.theme.direction,
            direction => direction,
        ),
    );
    const currentTheme = useSelector(
        createSelector(
            state => state.theme.currentTheme,
            currentTheme => currentTheme,
        ),
    );
    const isAppLayout = !(Layout === AuthLayout);
    const { modal } = App.useApp();

    // 토큰 재발행 관련 로직
    useEffect(() => {
        const authToken = localStorage.getItem(AUTH_TOKEN); // 토큰 GET
        if ((authToken === null && location.pathname !== '/') || location.pathname === '/sso-success') {
            AuthService.newAccessToken().catch(err => {
                // 1001 로그아웃, 700 중복로그인
                if ((err.status === 401 || err.status === 1001 || err.status === 700) && isAppLayout) {
                    modal.error(gfnAlert(err.message)).then(flag => {
                        if (flag) store.dispatch(signOut());
                    });
                }
            });
        }
    }, []);

    useBodyClass(`dir-${direction}`);
    const themeConfig = currentTheme === 'light' ? { ...lightTheme } : { ...darkTheme };

    // 공통 Validation Message
    const typeTemplate = '${label}은(는) 유효하지 않은 ${type}형식입니다.';
    const validateMessages = {
        required: '${label}은(는) 필수 입력입니다.',
        whitespace: '${label}은(는) 비워둘 수 없습니다.',
        types: {
            string: typeTemplate,
            method: typeTemplate,
            array: typeTemplate,
            object: typeTemplate,
            number: typeTemplate,
            date: typeTemplate,
            boolean: typeTemplate,
            integer: typeTemplate,
            float: typeTemplate,
            regexp: typeTemplate,
            email: typeTemplate,
            url: typeTemplate,
            hex: typeTemplate,
        },
        date: {
            format: '${label}은(는) 유효하지 않은 날짜 형식입니다.',
            parse: '${label}은(는) 날짜 형식으로 변환될 수 없습니다.',
            invalid: '${label}은(는) 유효하지 않은 날짜입니다.',
        },
        string: {
            len: '${label}은(는) ${len}글자여야 합니다.',
            min: '${label}은(는) 적어도 ${min}글자 이상이어야 합니다.',
            max: '${label}은(는) ${max}자 이하로 입력하여야 합니다',
            range: '${label}은(는) ${min}-${max}글자 사이어야 합니다.',
        },
        number: {
            len: '${label}의 값은 ${len}이어야 합니다.',
            min: '${label}의 최솟값은 ${min}입니다.',
            max: '${label}의 최댓값은 ${max}입니다.',
            range: '${label}의 값은 ${min}-${max} 사이어야 합니다.',
        },
        array: {
            len: '${label}은(는) ${len}이어야 합니다.',
            min: '${label}은(는) 최소 ${min}이어야 합니다.',
            max: '${label}은(는) 최대 ${max}이어야 합니다.',
            range: '${label}은(는) ${min}-${max} 사이어야 합니다.',
        },
        pattern: {
            mismatch: '${label}은(는) ${pattern} 형식으로 입력하여야 합니다.',
        },
    };

    return (
        <ConfigProvider
            theme={themeConfig}
            direction={direction}
            locale={ko_KR}
            componentSize="small"
            form={{ validateMessages }}
        >
            <Suspense fallback={<Loading cover="page" />}>
                <AxiosCustom isAppLayout={isAppLayout}>
                    <Layout />
                </AxiosCustom>
            </Suspense>
        </ConfigProvider>
    );
};

export default memo(Layouts);
