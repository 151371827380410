import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from 'services/AuthService';
import { AUTH_TOKEN, USER_DATA } from '../../constants/AuthConstant';

export const initialState = {
    loading: false,
    loadingBar: false,
    signMessage: false,
    message: null,
    showMessage: false,
    redirect: '/',
    token: localStorage.getItem(AUTH_TOKEN) || null,
    userData:
        JSON.parse(
            localStorage.getItem(USER_DATA) === null || localStorage.getItem(USER_DATA) === undefined
                ? '{}'
                : localStorage.getItem(USER_DATA),
        ) || null,
};

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
    return AuthService.login(data)
        .then(response => {
            const {
                data: { accessToken, userData, message },
            } = response;
            if (accessToken === undefined) {
                return rejectWithValue(message || 'Error');
            } else {
                localStorage.setItem(AUTH_TOKEN, accessToken);
                localStorage.setItem(USER_DATA, JSON.stringify(userData));
                return response.data;
            }
        })
        .catch(err => {
            //console.log(err);
            return rejectWithValue(err.message || 'Error');
        });
});

export const signOut = createAsyncThunk('auth/signOut', async (data, { rejectWithValue }) => {
    return AuthService.signOutRequest()
        .then(response => {
            if (response.data) {
                localStorage.removeItem(AUTH_TOKEN);
                localStorage.removeItem(USER_DATA);
                return response.data;
            } else {
                return rejectWithValue(response.message);
            }
        })
        .catch(err => {
            //console.log(err);
            return rejectWithValue(err.message || 'Error');
        });
});

export const newAccessToken = createAsyncThunk('auth/newAccessToken', async data => {
    //   console.log("newAccessToken", data);
    return data;
});

export const userInfoUpdate = createAsyncThunk('auth/userInfoUpdate', async data => {
    return data;
});

export const signMessage = createAsyncThunk('auth/signMessage', async data => {
    return data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.showMessage = false;
            state.message = null;
            state.loading = false;
            state.loadingBar = false;
            state.signMessage = false;
            state.redirect = '/';
            state.token = action.payload.accessToken;
            state.userData = action.payload.userData;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
            state.loadingBar = false;
            state.signMessage = false;
        },
        hideAuthMessage: state => {
            state.showMessage = false;
            state.message = null;
        },
        signOutSuccess: state => {
            state.showMessage = false;
            state.message = null;
            state.loading = false;
            state.loadingBar = false;
            state.signMessage = false;
            state.token = null;
            state.userData = null;
            state.redirect = '/';
        },
        showLoading: state => {
            state.loading = true;
            state.loadingBar = true;
        },
        signInSuccess: (state, action) => {
            state.showMessage = false;
            state.message = null;
            state.loading = false;
            state.loadingBar = false;
            state.signMessage = true;
            state.token = action.payload.accessToken;
            state.userData = action.payload.userData;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(signIn.pending, state => {
                state.showMessage = false;
                state.message = null;
                state.loading = true;
                state.loadingBar = true;
                state.signMessage = false;
                state.token = null;
                state.userData = null;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.showMessage = false;
                state.message = null;
                state.loading = false;
                state.loadingBar = false;
                state.signMessage = true;
                state.redirect = '/app/home';
                state.token = action.payload.accessToken;
                state.userData = action.payload.userData;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
                state.loadingBar = false;
                state.signMessage = false;
                state.token = null;
                state.userData = null;
            })
            .addCase(signOut.fulfilled, state => {
                state.showMessage = false;
                state.message = null;
                state.loading = false;
                state.loadingBar = false;
                state.signMessage = false;
                state.token = null;
                state.userData = null;
                state.redirect = '/';
            })
            .addCase(signOut.rejected, state => {
                state.showMessage = false;
                state.message = null;
                state.loading = false;
                state.loadingBar = false;
                state.signMessage = false;
                state.token = null;
                state.userData = null;
                state.redirect = '/';
            })
            .addCase(newAccessToken.fulfilled, (state, action) => {
                state.token = action.payload.accessToken;
                state.userData = action.payload.userData;
            })
            .addCase(newAccessToken.rejected, state => {
                state.showMessage = false;
                state.message = null;
                state.loading = false;
                state.signMessage = false;
                state.token = null;
                state.userData = null;
            })
            .addCase(userInfoUpdate.fulfilled, (state, action) => {
                state.showMessage = false;
                state.message = null;
                state.loading = false;
                state.loadingBar = false;
                state.signMessage = false;
                state.userData = action.payload;
            });
    },
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess } =
    authSlice.actions;

export default authSlice.reducer;
