import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSliceCustom';
import tabs from './tabs';

const rootReducer = asyncReducers => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    tabs,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
