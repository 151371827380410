import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';

import { CookiesProvider } from 'react-cookie';
import { App as AntdApps, Modal } from 'antd';

const themes = {
    // eslint-disable-next-line no-undef
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    // eslint-disable-next-line no-undef
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// router change

function App() {
    history.listen(() => {
        Modal.destroyAll();
    });

    return (
        <AntdApps>
            <div className="App">
                <Provider store={store}>
                    <CookiesProvider>
                        <BrowserRouter history={history}>
                            <ThemeSwitcherProvider
                                themeMap={themes}
                                defaultTheme={THEME_CONFIG.currentTheme}
                                insertionPoint="styles-insertion-point"
                            >
                                <Layouts />
                            </ThemeSwitcherProvider>
                        </BrowserRouter>
                    </CookiesProvider>
                </Provider>
            </div>
        </AntdApps>
    );
}

export default App;
