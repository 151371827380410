import axios from 'axios';
import store from '../../store';
import { signOut } from '../../store/slices/authSliceCustom';
import { baseAdapter, blobAdapter } from './Adapters';
import AuthService from '../../services/AuthService';
import { App } from 'antd';

const AxiosCustom = props => {
    const { isAppLayout } = props;
    const { notification } = App.useApp();
    axios.defaults.withCredentials = true;

    axios.defaults.adapter = config => {
        if (config.responseType !== 'blob') {
            return baseAdapter(config);
        } else {
            return blobAdapter(config);
        }
    };

    axios.interceptors.request.use(
        config => {
            const {
                auth: { token },
            } = store.getState();
            const jwtToken = token || null;
            if (jwtToken) {
                config.headers['Authorization'] = `Bearer ${jwtToken}`;
            }
            // method는 항상 post
            // method 지시자는 'X-HTTP-Method-Override' 로 전달.
            const { method } = config;
            if (method.toLowerCase() !== 'post') {
                config.method = 'post';
                config.headers['X-HTTP-Method-Override'] = `${method}`;
            }
            return config;
        },
        error => {
            notification.error({ title: '오류', message: '요청을 처리할 수 없습니다.' });
            return { ...error, ...{ message: '요청을 처리할 수 없습니다.' } };
        },
    );

    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const { config, status, name } = error;

            if (name === 'AxiosError') {
                return Promise.reject({ ...error, ...{ message: 'API 서비스 장애가 발생하였습니다.' } });
            }

            if ([700, 701, 1001].indexOf(status) > -1 && isAppLayout && !config.sent) {
                config.sent = true;
                store.dispatch(signOut());
                return Promise.reject(error);
            } else if (status === 901 && !config.sent) {
                config.sent = true;
                return AuthService.newAccessToken()
                    .then(() => axios(config))
                    .catch(err => {
                        return Promise.reject(err);
                    });
            } else {
                return Promise.reject(error);
            }
        },
    );

    return <>{props.children}</>;
};
export default AxiosCustom;
