import axios from "axios";

/**
 * axios Adapter 모듈
 */

/**
 * 기본 Adapter
 * @param config
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const baseAdapter = (config) => {
    return axios.getAdapter('xhr')(config).then(response=> {

        if(Object.hasOwn(response,"data") && config.url !== "/logout-process") {
            response.data = JSON.parse(response.data);
        }

        if(Object.hasOwn(response,"data") && Object.hasOwn(response.data,"status")) {
            const responseData = {...response, ...response.data, data: null, statusText: "ERROR"};
            delete responseData.data;
            return Promise.reject(responseData);
        }else{
            return Promise.resolve(response);
        }
    }).catch(error => Promise.reject(error));
};

/**
 * "blob" type의 Response가 지정되었을 때 사용될 Adapter
 * @param config
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const blobAdapter = (config) => {
    return axios.getAdapter('xhr')(config).then(async response=> {
        if(response.data && config.url !== "/logout-process" && response.data.type === "application/json") {
            const rtnValue = await response.data.text().then(data => JSON.parse(data));
            return Promise.reject({...rtnValue, config});
        }else {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.style.display = "none";
            link.download = decodeURI(response.headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, ''));
            document.body.appendChild(link);
            link.click();
            link.remove();
            return Promise.resolve(response);
        }
    }).catch(error => Promise.reject(error));
};