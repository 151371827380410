import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: '',
    tabItems: [],
};

const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload
    },
    addTabMenu: (state, action) => {
      // const isTabExists = state.tabMenus.some(
      //   (tabMenu) => tabMenu.path === action.payload.path
      // );
      // if (!isTabExists) {
      state.tabItems.push(action.payload)
      // }
    },
    resetTabMenus: () => initialState,
  },
})

export const { setValue, addTabMenu, removeTabMenu, resetTabMenus } = tabSlice.actions

export default tabSlice.reducer
