// import service from 'auth/FetchInterceptor'
import axios from 'axios';
import store from '../store';
import { newAccessToken } from '../store/slices/authSliceCustom';
import { AUTH_TOKEN, USER_DATA } from '../constants/AuthConstant';

const AuthService = {};
AuthService.login = function (data) {
    return axios.postForm('/login-process', data);
};

AuthService.newAccessToken = function () {
    return axios
        .get('/api/access-token')
        .then(res => {
            if (res !== undefined && res != null && res.data !== undefined && res.data.accessToken !== undefined) {
                store.dispatch(newAccessToken(res.data));
                localStorage.setItem(AUTH_TOKEN, res.data.accessToken);
                localStorage.setItem(USER_DATA, JSON.stringify(res.data.userData));
                return Promise.resolve(res);
            } else {
                return Promise.reject(new Error(res));
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

AuthService.signOutRequest = function () {
    return axios.post('/logout-process');
};

export default AuthService;
